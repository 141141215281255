<template>
  <div class="upload">
    <p class="theme-color">银行证明</p>
    <div class="image-bank">
      <input
        type="file"
        accept="image/*,application/pdf"
        class="image"
        id="uploadImage"
        style="visibility: hidden"
        @change="selectFileImage($event)"
      />
      <label for="uploadImage">
        <van-image class="showimage-bank" :src="pic || defaultPic"></van-image>
      </label>
    </div>
    <div v-if="pdf_name" class="pdf-name">{{pdf_name}}</div>
    <div class="upload-tip theme-color">点击图片上传或更新（jpg/jpeg/png/pdf）</div>
    <div class="common-bank-tip theme-color">
      <span>提示：</span><br />
      <span>1.银行证明必须与基本资料里填写的银行账号一致。</span><br />
      <span>2.银行提款卡或银行结单。</span><br />
      <span>3.需要清晰显示银行名称、姓名、银行号码。</span><br />
      <span>4.示例（点击可查看）：<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_4bank_01.jpg')">
        银行卡
      </span>、<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_4bank_02.jpg')">
        银行结单
      </span>。</span><br />
    </div>

  </div>
</template>

<script>
import {
  Uploader, Image, ImagePreview,
} from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import httpApi from '../../utils/httpApi';
import uploadImage from './uploadImage';
import util from '../../utils/util';

const defaultPdf = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/7_pdf.png';
const defaultImage = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/3_live.png';
export default {
  name: 'Home',
  components: {
    [Uploader.name]: Uploader,
    [Image.name]: Image,
  },
  data() {
    return {
      defaultPic: defaultImage,
    };
  },
  methods: {
    async selectFileImage(event) {
      const file = event.target.files[0];
      if (file.type === 'application/pdf') {
        const form = new FormData();
        form.append('file', file, this.$util.setPdfName(file.name));
        const result = await this.$api.uploadPdf('bank_pdf', form);
        const { data = {} } = (result || { data: {} }).data;
        if (data.fileName.endsWith('.pdf')) {
          this.pic = defaultPdf;
          this.pdf_name = this.$util.formatPdfName(data.fileName);
        }
      } else {
        uploadImage(file, 'bank_photo', (photo) => {
          this.pic = photo;
          this.pdf_name = '';
        });
      }
    },
    showPreview(key) {
      ImagePreview([key]);
    },
    before() {
      this.$router.go(-1);
    },
  },
  setup() {
    const pic = ref('');
    const pdf_name = ref('');
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '上传资料');
      store.commit('setStepsShow', false);
      const result = await httpApi.getUploadImgesByType({ type: 'bank_photo' });
      if (!result) return;
      const { value } = result.data;
      if (!value) return;
      if (value.endsWith('.pdf')) {
        pic.value = defaultPdf;
        pdf_name.value = util.formatPdfName(value);
      } else {
        pic.value = value;
      }
    });
    return {
      pic,
      pdf_name,
    };
  },
};
</script>

<style lang="less">
  .image-bank {
    width: 300px;
    height: 200px;
    margin: auto;
    position: relative;
  }
  .showimage-bank {
    position: absolute;
    width: 300px;
    height: 200px;
    top: 0;
    left: 0;
  }
  .pdf-name {
    margin: auto;
    text-align: center !important;
    margin: 20px;
  }
  .upload-tip {
    text-align: center !important;
    color: @font-color-2;
    margin-top: @space-2;
  }
  .common-bank-tip {
    margin-top: 10px;
    color: @font-color-1;
    padding-left: 0px;
    padding-right: 0px;
  }
</style>
